export const initialValues = {
  tin: "",
  account: "",
  name: "",
  mfo: "",
  balance: "",
  email: "",
  photo: "",
  phone: "",
};
