//@ts-ignore
const generateActText = (sellerName, buyerName) => {
  return `Мы, нижеподписавшиеся, "${
    sellerName || "__________"
  }" именуемое в дальнейшем Исполнитель, с одной стороны и "${
    buyerName || "__________"
  }" именуемое в дальнейшем Заказчик, с другой стороны составили настоящий Акт о том, что работы выполнены в соответствии с условиями Заказчика в полном объеме.`;
};

export default generateActText;
