export const initialValues = {
  actDoc: {
    actNo: "",
    actDate: "",
    actText: "",
  },
  contractDoc: { contractNo: "", contractDate: "" },
  sellerTin: "",
  sellerName: "",
  sellerBranchCode: "",
  sellerBranchName: "",
  buyerTin: "",
  buyerName: "",
  buyerBranchCode: "",
  buyerBranchName: "",
};
