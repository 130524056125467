export const initialValues = {
  docNo: "",
  docDate: "",
  contractNo: "",
  contractDate: "",
  sellerTin: "",
  sellerName: "",
  buyerTin: "",
  buyerName: "",
  document: "",
  documentName: "",
};
