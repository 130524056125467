import { Icon } from "@iconify/react";
import closeCircleFill from "@iconify/icons-eva/close-circle-fill";

// ----------------------------------------------------------------------

// @ts-ignore
export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={closeCircleFill} />,
      },

      styleOverrides: {
        label: {
          marginTop: -4,
        },
        colorDefault: {
          "& .MuiChip-avatarMedium, .MuiChip-avatarSmall": {
            color: theme.palette.text.secondary,
          },
        },
        outlined: {
          borderColor: theme.palette.grey[500_32],
          "&.MuiChip-colorPrimary": {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
  };
}
