export const initialValues = {
  version: "1",
  facturaType: 0,
  singleSidedType: 0,
  hasMarking: false,
  facturaDoc: { facturaNo: "", facturaDate: "" },
  oldFacturaDoc: {
    oldFacturaId: null,
    oldFacturaNo: null,
    oldFacturaDate: "",
  },
  contractDoc: { contractNo: "", contractDate: "" },
  facturaEmpowermentDoc: {
    empowermentNo: "",
    empowermentDateOfIssue: "",
    agentFio: "",
    agentTin: "",
    agentPinfl: "",
  },
  itemReleasedDoc: {
    itemReleasedTin: "",
    itemReleasedFio: "",
    itemReleasedPinfl: "",
  },
  sellerTin: "",
  buyerTin: "",
  // realizationPurpose: "",
  seller: {
    name: "",
    account: "",
    // category: "",
    bankId: "",
    address: "",
    mobile: "",
    workPhone: "",
    oked: "",
    districtId: "",
    director: "",
    accountant: "",
    vatRegCode: "",
    branchCode: "",
    branchName: "",
  },
  buyer: {
    name: "",
    account: "",
    bankId: "",
    address: "",
    mobile: "",
    workPhone: "",
    oked: "",
    districtId: "",
    director: "",
    accountant: "",
    vatRegCode: "",
    branchCode: "",
    branchName: "",
  },
  foreignCompany: {
    countryId: "",
    name: "",
    address: "",
    bank: "",
    account: "",
  },
  productList: {
    facturaProductId: "",
    tin: "",
    hasExcise: false,
    hasVat: true,
    hasCommittent: false,
    hasLgota: false,
    hasMedical: false,
  },
};
